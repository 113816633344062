import React from "react"
import OfertaSezonowaPodwojna from "../OfertaSezonowaPodwojna"
import Snowfall from "react-snowfall"
import { Button, Image } from "react-bootstrap"
import "./style.scss"
import f1 from "../../images/oferty/ferie2025/ferie 2025.png"
import f2 from "../../images/oferty/ferie2025/ferie 2025-2.png"

const imgStyle = { width: "calc(100% - 50px)", maxWidth: "1500px", borderRadius: "50px 50px 0 0" }
const imgStyle2 = { ...imgStyle, borderRadius: "0 0 50px 50px" }
const FerieOferty = () => (
  <>

    <div className={"text-center mt-5"}>
      <br/>

      <h2 className="m-4 title">Zimowe półkolonie z Profil Dance - FERIE 2025</h2>
      <p className="mx-5 subtitle">
        Zapisz się (albo swoją pociechę, jeśli masz taką pod ręką) na półkolonie zimowe 10-14 lutego 2025! Kliknij
        przycisk poniżej i dołącz do zabawy!
      </p>

      <Button
        variant="primary" // Zmieniamy na klasyczny lub bardziej elegancki wariant
        size="lg"
        className="m-3 btn-custom" // Dodajemy własną klasę
        href="https://forms.gle/KJJ2P4wL9EFhXVWD6"
      >
        Wypełnij formularz <br/> zgłoszeniowy na półkolonie! 🎉
      </Button>

      <Image src={f1} style={imgStyle}/>
      <Image src={f2} style={imgStyle2}/>
      <Button
        variant="primary" // Zmieniamy na klasyczny lub bardziej elegancki wariant
        size="lg"
        className="m-3 btn-custom" // Dodajemy własną klasę
        href="https://forms.gle/KJJ2P4wL9EFhXVWD6"
      >
        Wypełnij formularz <br/> zgłoszeniowy na półkolonie! 🎉
      </Button>

    </div>
    {/*<p>Wakacyjny obóz akrobatyczno-taneczny odbędzie się w terminie 14-27 lipca 2024. Szczegóły już wkrótce</p>*/}
    {/*<p>Letnie warsztaty stacjonarne odbędą się w terminie 19-23 sierpnia 2024</p>*/}
    {/*    <OfertaSezonowaPodwojna
      title={"Wakacje 2023"}
      offers={[{
        name: "Taneczne lato 2023", src: require("../../images/oferty/lato_2023_warsztaty.png"),
        description: "Letnie warsztaty taneczno-artystyczne, 21-25 sierpnia 2023", subdescription: "",
        pdf: require("../../images/oferty/lato-2023-acro-dance-art-fun-1.pdf"),
      },
        {
          name: "Obóz 2023", src: require("../../images/oferty/oboz2023.png"),
          description: "Chmielno, 16-29 lipca 2023", subdescription: "",
          pdf: require("../../images/oferty/Obóz-2023.pdf"),
        }
      ]}
      moreInfoFile={require("../../images/oferty/LETNIE-WARSZTATY-DLA-DZIECI-ORGANIZOWANE-PRZEZ-PROFIL-DANCE.pdf")}
    ></OfertaSezonowaPodwojna>
    <MoreInfo title={"UMOWA OBÓZ 23"} moreInfoFile={require("../../images/oferty/umowa-obóz-K-23.pdf")} pages={2}/>
    <MoreInfo title={"Karta kwalifikacyjna uczestnika wypoczynku"} moreInfoFile={require("../../images/oferty/Karta-kwalifikacyjna-uczestnika-wypoczynku.pdf")} pages={2}/>
  */}
    {/*</>*/}

    {/*<p>Zimowe warsztaty stacjonarne odbędą się w terminie 22-26 stycznia 2024. Szczegóły już wkrótce</p>*/}
    {/*<p>Zimowe warszaty stacjonarne odbędą się w terminie 22-26 stycznia 2024.</p>*/}
    {/*  <OfertaSezonowaPodwojna*/}
    {/*  title={""}*/}
    {/*  offers={[{*/}
    {/*    name: "Ferie", src: require("../../images/oferty/ferie 2023.png"),*/}
    {/*    description: "FERIE 2024  22 - 26 stycznia",*/}
    {/*    subdescription: "",*/}
    {/*    pdf: require("../../images/oferty/Ferie 2024_compressed.pdf"),*/}
    {/*  }*/}
    {/*    ,*/}
    {/*    // {*/}
    {/*    //   name: "Dance, Art & Fun", src: require("../../images/oferty/Acro, dance & fun - plakat (3).png"),*/}
    {/*    //   description: "FERIE 2022 turnus 2 - Ursynów 07-11.02", subdescription: "",*/}
    {/*    //   pdf: require("../../images/oferty/Acro, dance & fun - plakat (1).pdf"),*/}
    {/*    // },*/}
    {/*  ]}*/}
    {/*  moreInfoFile={require("../../images/oferty/ZIMOWE WARSZTATY - FERIE 2024_compressed.pdf")}*/}
    {/*  hideIkonkaDoKlikania={true}*/}
    {/*></OfertaSezonowaPodwojna>*/}
    <Snowfall color="#ffffff" snowflakeCount={50}/>

  </>)

export default FerieOferty
