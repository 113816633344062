import { Button, Col, Image, Modal, Row, Spinner } from "react-bootstrap"
import React, { useState } from "react"
import "./style.scss"
import { Document, Page } from "react-pdf"
import { SizeMe } from "react-sizeme"
import { Link } from "gatsby"

const OfertaSezonowaPodwojna = (props) => (
  <>
    <h1 className={"text-center"}>{props.title}</h1>
    <Row>
      { !props.hideIkonkaDoKlikania &&  props.offers.map((offer) =>
        <IkonkaDoKlikania {...offer} centered={props.offers.length === 1} />)}
    </Row>
    {props.offers.length === 1 && <MoreInfo title={props.offers[0].title} moreInfoFile={props.offers[0].pdf}  hidetitle={props.offers[0].hidetitle} />}
    {props.moreInfoFile && <MoreInfo title={"Szczegółowe informacje dotyczące oferty " + props.title} moreInfoFile={props.moreInfoFile} />}
  </>
)
export const MoreInfo = (props) => {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <div className={"text-center"}>

      <br/>
      {!props.hidetitle &&
        <h4 style={{ margin: "auto" }} className={"mt-5 mb-4"}>{props.title} (<a
          href={props.moreInfoFile} target={"_blank"}
          className="link-no-style">
          <Button primary>Pobierz pdf</Button>)
        </a></h4>
      }
      <SizeMe>
        {({ size }) => (
          <Document file={props.moreInfoFile} onLoadSuccess={onDocumentLoadSuccess} loading={
            <>
              <div>Ładowanie oferty...</div>
              <Spinner animation="grow" variant="brown">
                <span className="sr-only">Loading...</span>
              </Spinner><Spinner animation="grow" variant="brown">
              <span className="sr-only">Loading...</span>
            </Spinner><Spinner animation="grow" variant="brown">
              <span className="sr-only">Loading...</span>
            </Spinner>
            </>
          }>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} width={size.width || 1} />
            ))}
          </Document>
        )}
      </SizeMe>
    </div>
  )
}
const IkonkaDoKlikania = (props) => {
  const [modalShow, setModalShow] = React.useState(false)
  return (
    <>
      <Col lg={props.centered ? { span: 6, offset: 3 } : 6} className={"ikonka"} onClick={() => setModalShow(true)}>
        <h4 className={"text-center pt-3 pb-3"}>{props.name}</h4>
        <div className={"ikonkadoklikaniaImage"}>
          <Image src={props.src} className="ikonkadoklikaniaImageimage"/>
          <div className="overlay text-center text-brown">+</div>
        </div>
        <h5 className={"text-center pt-3 pb-0"}>{props.description}</h5>
        <h5 className={"text-center pt-0 pb-3"}>{props.subdescription} {" "}
          {props.url ?
            <Button variant={'violet'}
                    href={props.url}>Zapisz się!</Button>
          :
          <Link to='/zapisy' className="link-no-style">
            <Button onClick={props.onHide} variant={"brown"}>Zapisz się!</Button>
          </Link>
          }
        </h5>
      </Col>
      <PDFModal show={modalShow} onHide={() => setModalShow(false)} {...props} />
    </>
  )
}


function PDFModal(props) {
  const [numPages, setNumPages] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Modal{...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      /*className={"modalInstruktor"}*/
    >
      <Modal.Header closeButton>
        {/*<h4>{props.name}</h4>*/}
      </Modal.Header>
      <Modal.Body>
        <SizeMe>
          {({ size }) => (
            <>
              <Document file={props.pdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                        loading={
                <>
                  <div>Ładowanie oferty...</div>
                  <Spinner animation="grow" variant="brown">
                    <span className="sr-only">Loading...</span>
                  </Spinner><Spinner animation="grow" variant="brown">
                  <span className="sr-only">Loading...</span>
                </Spinner><Spinner animation="grow" variant="brown">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                </>
              }>
                {Array(numPages).fill(1).map((el, i) =>
                  <Page pageNumber={i+1} width={size.width ? size.width : 1}/>
                )}
              </Document>
            </>
          )}
        </SizeMe>
      </Modal.Body>
      <Modal.Footer>
        <a href={props.pdf} target={"_blank"} className="link-no-style">
          <Button onClick={props.onHide} primary>Pobierz pdf</Button>
        </a>
        <Button onClick={props.onHide}>Zamknij</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default OfertaSezonowaPodwojna
